









































import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'BrandLinkModal',
  components: {
    SimpleModal,
  },
  setup() {
    const { modal } = useUiState();

    const modalProps = computed(() => modal.value.props?.brandLink || {
      src: '',
      name: '',
    });
    const brandLink = (inkType: string) => `/${inkType}/${modalProps.value?.name}.html`;

    return {
      modalProps,
      name: MODAL_NAMES.brandLink,
      brandLink,
    };
  },
});
